<script setup lang="ts">
const props = defineProps<{
  src?: string | boolean
  alt?: string
  text?: string
  icon?: string
}>()

const url = computed(() => {
  if (typeof props.src === 'boolean')
    return null

  return props.src
})

const placeholder = computed(() => {
  return (props.alt || '').split(' ').map(word => word.charAt(0)).join('').substring(0, 2)
})

const error = ref(false)

watch(() => props.src, () => {
  if (error.value)
    error.value = false
})

function onError() {
  error.value = true
}
</script>

<template>
  <span class="relative inline-flex shrink-0 items-center justify-center bg-slate-100 dark:bg-slate-800">
    <NuxtImg
      v-if="url && !error"
      :src="url"
      :alt="alt"
      @error="onError"
    />

    <span v-else-if="text" class="truncate text-slate-900 fw600 leading-none dark:text-white">{{ text }}</span>
    <NIcon v-else-if="icon" :icon="icon" class="shrink-0 text-slate-500 dark:text-slate-400" />
    <span v-else-if="placeholder" class="truncate text-slate-500 fw600 leading-none dark:text-slate-400">{{ placeholder }}</span>

  </span>
</template>

<style scoped>

</style>
